<template>
    <v-card tile flat class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="green--text">{{ roleDisplayName }} ({{label}})</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="green" @click="openNewPaletteColorDialog" class="noprint">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <!-- <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="green" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-site-settings' }">
                        <v-list-item-content>
                            <v-list-item-title>Settings</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu> -->
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No {{ role }} colors yet.</v-list-item>
            <v-list-item v-for="(item, idx) in list" v-bind:key="idx" class="my-2" @click="openEditPaletteColorDialog(idx, item)">
                <v-list-item-avatar :color="item.hex">
                </v-list-item-avatar>
                <v-list-item-content class="text-start">
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.hex }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="green" v-on="on">
                            <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width class="noprint"/>
                        </v-btn>
                        <!-- <v-tab v-on="on" class="green--text">
                            <Avatar :attr="mainProcolor" :size="36"/>
                            <font-awesome-icon icon="caret-down" class="pl-2 green--text" fixed-width size="1x"/>
                        </v-tab> -->
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item-group>
                        <v-list-item @click="openDeletePaletteColorDialog(idx, item)">
                            <!-- <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'trash']" style="font-size: 20px; color: red" fixed-width/>
                            </v-list-item-icon> -->
                            <v-list-item-content>
                                <v-list-item-title style="color: red;">Delete...</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-dialog v-model="newPaletteColorDialog" max-width="600">
        <v-card tile elevation="4" class="pa-0" max-width="600">
            <v-toolbar short flat color="white">
                <v-toolbar-title class="green--text">Add a color to the palette</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="px-5">
            <v-form @submit.prevent="saveNewPaletteColor" @keyup.enter.native="saveNewPaletteColor" class="px-2">
                <v-row>
                <v-col>
                    <!-- <p> -->
                        <!-- All of this information will be published with the BrandProfile. -->
                        <!-- The color label can be technically descriptive, such as "light blue", or it can be more emotionally descriptive, like "sunrise sky".
                        You can change it at any time. The color label is PUBLIC. -->
                    <!-- </p> -->
                    <p>Role: {{ roleDisplayName }}</p>

                    <v-text-field
                        ref="newPaletteColorLabelInput"
                        v-model="newPaletteColorLabel"
                        label="Display Name"
                        validate-on-blur
                        color="green"
                        required
                        type="text"
                        outlined
                        dense
                    >
                    </v-text-field>

                    <v-row no-gutters>
                    <!-- <v-select label="Role" :items="roleChoices" v-model="newPaletteColorRole" dense outlined class="mr-1"></v-select> -->

                    <!-- <v-text-field
                        label="Priority"
                        v-model="newPaletteColorPriority"
                        hint="1 is first priority, 99 is last priority"
                        type="number"
                        min="1"
                        max="99"
                        step="1"
                        dense
                        outlined
                        class="ml-1"
                        @change="validateNewPaletteColorPriority"
                    ></v-text-field> -->
                    <v-select :items="inputModeChoices" v-model="newPaletteColorInputMode" label="Input mode" dense outlined></v-select>
                    </v-row>

                    <v-color-picker
                        v-model="newPaletteColorHex"
                        required
                        :mode="newPaletteColorInputMode"
                        elevation="2"
                    >
                    </v-color-picker>

                </v-col>
                </v-row>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="4" class="green white--text" @click="saveNewPaletteColor" :disabled="!isNewPaletteColorComplete">
                    <span>Save</span>
                </v-btn>
                <v-btn text color="grey" @click="newPaletteColorDialog = false">
                    <span>Cancel</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <v-dialog v-model="editPaletteColorDialog" max-width="600">
        <v-card tile elevation="4" class="pa-0" max-width="600">
            <v-toolbar short flat color="white">
                <v-toolbar-title class="green--text">Edit a color on the palette</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="px-5">
            <v-form @submit.prevent="saveEditPaletteColor" @keyup.enter.native="saveEditPaletteColor" class="px-2">
                <v-row>
                <v-col>
                    <p>
                        All of this information will be published with the BrandProfile.
                        <!-- The color label can be technically descriptive, such as "light blue", or it can be more emotionally descriptive, like "sunrise sky".
                        You can change it at any time. The color label is PUBLIC. -->
                    </p>

                    <v-text-field
                        ref="editPaletteColorLabelInput"
                        v-model="editPaletteColorLabel"
                        label="Display Name"
                        validate-on-blur
                        color="green"
                        type="text"
                        outlined
                        dense
                    >
                    </v-text-field>

                    <v-row no-gutters>
                    <!-- <v-select label="Role" :items="roleChoices" v-model="editPaletteColorRole" dense outlined class="mr-1"></v-select> -->

                    <!-- <v-text-field
                        label="Priority"
                        v-model="editPaletteColorPriority"
                        hint="1 is first priority, 99 is last priority"
                        type="number"
                        min="1"
                        max="99"
                        step="1"
                        dense
                        outlined
                        class="ml-1"
                        @change="validateEditPaletteColorPriority"
                    ></v-text-field> -->
                    <v-select :items="inputModeChoices" v-model="editPaletteColorInputMode" label="Input mode" dense outlined></v-select>
                    </v-row>

                    <v-color-picker
                        v-model="editPaletteColorHex"
                        required
                        :mode="editPaletteColorInputMode"
                        elevation="2"
                    >
                    </v-color-picker>

                </v-col>
                </v-row>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="4" class="green white--text" @click="saveEditPaletteColor" :disabled="!isEditPaletteColorComplete">
                    <span>Save</span>
                </v-btn>
                <v-btn text color="grey" @click="editPaletteColorDialog = false">
                    <span>Cancel</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <v-dialog v-model="deletePaletteColorDialog" max-width="600">
        <v-card tile elevation="4" class="pa-0" max-width="600">
            <v-toolbar short flat color="white">
                <v-toolbar-title class="green--text">Delete a color from the palette</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="px-5">
            <v-form @submit.prevent="deletePaletteColor" @keyup.enter.native="deletePaletteColor" class="px-2">
                <v-row>
                <v-col>
                    <!-- <p> -->
                        <!-- The color will be deleted from the palette. -->
                        <!-- The color label can be technically descriptive, such as "light blue", or it can be more emotionally descriptive, like "sunrise sky".
                        You can change it at any time. The color label is PUBLIC. -->
                    <!-- </p> -->

                    <v-list dense nav>
                        <v-list-item class="my-2">
                            <v-list-item-avatar :color="deletePaletteColorHex">
                            </v-list-item-avatar>
                            <v-list-item-content class="text-start">
                                <v-list-item-title>{{ deletePaletteColorLabel }} [{{roleDisplayName}}]</v-list-item-title>
                                <v-list-item-subtitle>{{ deletePaletteColorHex }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                </v-col>
                </v-row>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="4" class="red white--text" @click="deletePaletteColor">
                    <span>Delete</span>
                </v-btn>
                <v-btn text color="grey" @click="deletePaletteColorDialog = false">
                    <span>Cancel</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <!-- <v-row justify="center" class="py-5" v-show="displayCreateColor">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-template v-model="createColor" ref="createColorRef" @submit="validateCreateOrigin" onSubmit="return false;" @keyup.enter.native="validateCreateOrigin">
                    <div v-if="add">
                        <v-select :items="addColorChoices" v-model="addColorId" label="Select an template"></v-select>
                    </div>
                    <div v-if="create">
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="green"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="site" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newOriginName
                            label="Display Name"
                            :rules="newOriginNameRules"
                            validate-on-blur
                            color="green"
                            required
                            hint="The name for the template"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="green white--text" @click="addOrigin" :disabled="!createColor" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="green white--text" @click="createOrigin" :disabled="!createColor" v-if="create">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-template>
            </v-card>
            </v-col>
        </v-row> -->
        <!-- <CreateOriginDialog v-model="createOriginDialogVisible" @cancel="createOriginDialogVisible = false" @created="createOriginDialogVisible = false"/> -->
    </v-card>
</template>

<script>
// import ColorListItem from '@/components/list-item/ColorListItem.vue';
// import CreateOriginDialog from '@/components/account-dashboard/CreateOriginDialog.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        // ColorListItem,
        // CreateOriginDialog,
    },

    props: {
        paletteId: {
            type: String,
            required: true,
        },
        role: {
            type: String,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        list: [],
        // createOriginDialogVisible: false,
        // create origin
        displayCreateColor: false,
        createColor: null,
        newColorName: null,
        newColorNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Email origin name is required',
        ],
        // add origin
        addColorChoices: [],
        addColorId: null,

        roleChoices: [
            { text: 'Primary', value: 'primary' },
            { text: 'Secondary', value: 'secondary' },
            { text: 'Accent', value: 'accent' },
            { text: 'Background', value: 'background' },
        ],

        inputModeChoices: [
            { text: 'HEXA - Hex with Alpha', value: 'hexa' },
            { text: 'RGBA - Red/Green/Blue/Alpha', value: 'rgba' },
            { text: 'HSLA - Hue/Saturation/Lightness/Alpha', value: 'hsla' },
        ],

        // for adding a new palette color
        newPaletteColorDialog: false,
        newPaletteColorLabel: null,
        newPaletteColorHex: null,
        newPaletteColorInputMode: 'hexa',
        // newPaletteColorRole: null,
        // newPaletteColorPriority: null,
        newPaletteColorTimestamp: null,

        // for editing an existing palette color
        editPaletteColorDialog: false,
        editPaletteColorId: null,
        editPaletteColorLabel: null,
        editPaletteColorHex: null,
        editPaletteColorInputMode: 'hexa',
        // editPaletteColorRole: null,
        editPaletteColorTimestamp: null,

        // for deleting an existing palette color
        deletePaletteColorDialog: false,
        deletePaletteColorId: null,
        deletePaletteColorLabel: null,
        deletePaletteColorHex: null,
        // deletePaletteColorRole: null,
        deletePaletteColorTimestamp: null,
    }),

    computed: {
        accountId() {
            return this.$route.params.accountId;
        },
        // id() {
        //     return this.policyId;
        // },
        label() {
            switch (this.list.length) {
            case 0:
                return 'No colors';
            case 1:
                return '1 color';
            default:
                return `${this.list.length} colors`;
            }
        },
        isNewPaletteColorComplete() {
            return this.newPaletteColorLabel !== null && this.newPaletteColorHex !== null;
        },
        isEditPaletteColorComplete() {
            return this.editPaletteColorLabel !== null && this.editPaletteColorHex !== null;
        },
        // the role values (input) are programming constants, but the display names (outputs) can be localized
        roleDisplayName() {
            switch (this.role) {
            case 'primary':
                return 'Primary';
            case 'secondary':
                return 'Secondary';
            case 'accent':
                return 'Accent';
            case 'background':
                return 'Background';
            default:
                return this.role;
            }
        },
    },

    watch: {
        content(newValue) {
            this.list = newValue?.[this.role] ?? [];
        },
        displayCreateColor(value) {
            if (value && (this.add || this.create)) {
                this.initAddColorChoices();
            }
        },
    },

    methods: {
        openNewPaletteColorDialog() {
            this.newPaletteColorLabel = null;
            this.newPaletteColorHex = null;
            // this.newPaletteColorRole = null;
            // this.newPaletteColorPriority = 1;
            this.newPaletteColorDialog = true;
        },
        openEditPaletteColorDialog(idx, item) {
            this.editPaletteColorId = idx;
            this.editPaletteColorLabel = item.label;
            this.editPaletteColorHex = item.hex;
            // this.editPaletteColorRole = item.role;
            this.editPaletteColorDialog = true;
        },
        openDeletePaletteColorDialog(idx, item) {
            this.deletePaletteColorId = idx;
            this.deletePaletteColorLabel = item.label;
            this.deletePaletteColorHex = item.hex;
            // this.deletePaletteColorRole = item.role;
            this.deletePaletteColorDialog = true;
        },
        async saveNewPaletteColor() {
            if (Number.isInteger(this.newPaletteColorTimestamp) && this.newPaletteColorTimestamp + 500 > Date.now()) {
                return;
            }
            this.newPaletteColorTimestamp = Date.now();
            this.list.push({
                label: this.newPaletteColorLabel,
                hex: typeof this.newPaletteColorHex === 'string' ? this.newPaletteColorHex : this.newPaletteColorHex.hexa,
            });
            this.$emit('edit', { paletteId: this.paletteId, role: this.role, list: this.list });
            this.newPaletteColorDialog = false;
        },
        async saveEditPaletteColor() {
            if (Number.isInteger(this.editPaletteColorTimestamp) && this.editPaletteColorTimestamp + 500 > Date.now()) {
                return;
            }
            this.editPaletteColorTimestamp = Date.now();
            this.$set(this.list, this.editPaletteColorId, {
                label: this.editPaletteColorLabel,
                hex: typeof this.editPaletteColorHex === 'string' ? this.editPaletteColorHex : this.editPaletteColorHex.hexa,
            });
            this.$emit('edit', { paletteId: this.paletteId, role: this.role, list: this.list });
            this.editPaletteColorDialog = false;
        },
        async deletePaletteColor() {
            if (Number.isInteger(this.deletePaletteColorTimestamp) && this.deletePaletteColorTimestamp + 500 > Date.now()) {
                return;
            }
            this.deletePaletteColorTimestamp = Date.now();
            this.list.splice(this.deletePaletteColorId, 1);
            this.$emit('edit', { paletteId: this.paletteId, role: this.role, list: this.list });
            this.deletePaletteColorDialog = false;
        },
        // async createOrigin() {
        //     this.error = false;
        //     console.log('createOrigin');
        //     const request = {
        //         // name: this.name,
        //         name: this.newOriginName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if origin arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the origin to an appropriate location after origin is created
        //     };
        //     const response = await this.$client.account(this.$route.params.accountId).currentAccount.site.create(request);
        //     console.log('createOrigin response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const origin = { id, ...request };
        //         this.list.push(site);
        //         this.$emit('created-site', origin);
        //         this.$emit('added-site', origin);
        //         this.displayCreateColor = false;
        //         this.newOriginName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // validateCreateOrigin() {
        //     if (this.$refs.createColorRef.validate()) {
        //         this.createOrigin();
        //     }
        // },

        // async addOrigin() {
        //     const origin = { id: this.addColorId, label: this.addColorChoices.find((item) => item.value === this.addColorId).text };
        //     this.list.push(site);
        //     this.$emit('added-site', origin);
        //     this.displayCreateColor = false;
        //     this.addColorId = null;
        // },
        // async initAddOriginChoices() {
        //     // create a map of which origin ids are already in the list
        //     const originMap = {};
        //     this.list.forEach((item) => {
        //         originMap[item.id] = true;
        //     });
        //     // when we show the add/create origin dialog, load the list of available origins to add to the policy (list of all origins less the ones already in the policy)
        //     const result = await this.$client.account(this.$route.params.accountId).currentAccount.site.list();
        //     console.log(`OriginViewList.vue fetch origins result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove origins already in the policy, then convert the available origins to a choice list for the v-select [ { text, value }, ... ]
        //         this.addColorChoices = result.list.filter((item) => !siteMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
    },

    mounted() {
        // this.loadPaletteColorList();
        this.list = this.content?.[this.role] ?? [];
    },
};
</script>
