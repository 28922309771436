<template>
    <v-row no-gutters>
        <v-col cols="12">
            <BrandBar :accountId="$route.params.accountId" :brandId="$route.params.brandId" :brand="brand" class="mb-6"></BrandBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4 noprint">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-palette', params: { accountId: this.$route.params.accountId } }">Palettes</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ palette.label }}</h1>
                <p class="text-caption text-center">Palette overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="palette">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card>
                        <v-toolbar color="teal" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Color Palette
                        </v-toolbar-title>
                        <template v-if="isDraft">
                            <v-spacer/>
                            <v-btn icon color="white green--text" @click="publish">
                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']"/>
                                <!-- Publish -->
                            </v-btn>
                        </template>
                        <!-- <v-progress-linear
                            :active="emailDispatchStatus && emailDispatchStatus.status === 'started'"
                            :value="emailDispatchProgress"
                            absolute
                            bottom
                            color="green"
                        ></v-progress-linear> -->
                        </v-toolbar>
                        <v-card-text>

                    <p class="text-overline mb-0">Label</p>
                    <p class="mb-0 pb-0" v-if="!isEditingPaletteLabel">
                        {{ palette.label }}
                        <v-btn icon color="green" @click="isEditingPaletteLabel = true" class="noprint">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>
                    <v-form @submit.prevent="saveEditPaletteLabel" v-if="isEditingPaletteLabel">
                        <v-text-field
                            ref="paletteLabelInput"
                            v-model="editPaletteLabel"
                            label="Label"
                            :rules="newPaletteLabelRules"
                            validate-on-blur
                            color="green"
                            required
                            type="text"
                            outlined
                            dense
                        >
                            <template #append-outer>
                                <v-btn icon color="green" @click="saveEditPaletteLabel">
                                    <font-awesome-icon :icon="['fas', 'check']"/>
                                </v-btn>
                                <v-btn icon color="grey" @click="isEditingPaletteLabel = false">
                                    <font-awesome-icon :icon="['fas', 'times']"/>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-form>

                    <p class="text-overline mb-0 mt-8">Mode</p>
                    <p class="mb-0 pb-0" v-if="!isEditingPaletteMode">
                        {{ paletteMode }}
                        <!-- <v-btn icon color="green" @click="isEditingPaletteMode = true" class="noprint">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn> -->
                    </p>
                    <v-form v-if="isEditingPaletteMode">
                        <v-select :items="modeChoices" v-model="editPaletteMode">
                            <template #append-outer>
                                <v-btn icon color="green" @click="saveEditPaletteMode">
                                    <font-awesome-icon :icon="['fas', 'check']"/>
                                </v-btn>
                                <v-btn icon color="grey" @click="isEditingPaletteMode = false">
                                    <font-awesome-icon :icon="['fas', 'times']"/>
                                </v-btn>
                            </template>
                        </v-select>
                    </v-form>

                    <!-- <p class="text-overline mb-0 mt-8">Media Type</p>
                    <p class="mb-0 pb-0" v-if="!isEditingPaletteMediaType">
                        {{ paletteMediaType }}
                        <v-btn icon color="green" @click="isEditingPaletteMediaType = true" class="noprint">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>
                    <v-form v-if="isEditingPaletteMediaType">
                        <v-select :items="mediaTypeChoices" v-model="editPaletteMediaType">
                            <template #append-outer>
                                <v-btn icon color="green" @click="saveEditPaletteMediaType">
                                    <font-awesome-icon :icon="['fas', 'check']"/>
                                </v-btn>
                                <v-btn icon color="grey" @click="isEditingPaletteMediaType = false">
                                    <font-awesome-icon :icon="['fas', 'times']"/>
                                </v-btn>
                            </template>
                        </v-select>
                    </v-form> -->

                        </v-card-text>

                        <PaletteColorList :paletteId="palette.id" role="primary" :content="palette.content" @edit="saveEditPaletteColorList"/>
                        <PaletteColorList :paletteId="palette.id" role="secondary" :content="palette.content" @edit="saveEditPaletteColorList"/>
                        <PaletteColorList :paletteId="palette.id" role="accent" :content="palette.content" @edit="saveEditPaletteColorList"/>
                        <PaletteColorList :paletteId="palette.id" role="background" :content="palette.content" @edit="saveEditPaletteColorList"/>

                    </v-card>

                    <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                    <p class="mb-0 pb-0">
                        {{ palette.comment }}
                        <v-btn icon color="green" @click="editPaletteComment">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p> -->

                    <!-- <p class="text-overline mb-0 mt-8">Palette ID</p>
                    <p class="mb-0 pb-0">
                        {{ palette.id }}
                    </p> -->

                    <!-- TODO: show the usages of the palette, such as which palettes, which images, etc. -->
                    <!-- <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="affectedEmailContactList.length > 0">
                            This palette is subscribed by {{ affectedEmailContactList.length }} email contacts.
                            < ! - - TODO: show the affectedEmailContactList - - >
                        </span>
                        <span v-if="affectedEmailContactList.length === 0">
                        This palette is not currently applied to any email contacts.
                        </span>
                    </p> -->

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, paletteId: this.$route.params.paletteId } }">Access control</router-link>
                    </p> -->

                    <v-expansion-panels class="mt-8 noprint" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Palette Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.palette, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editPaletteLabelDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the palette label</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The palette label can be technically descriptive, such as "light blue", or it can be more emotionally descriptive, like "sunrise sky".
                            You can change it at any time. The palette label is PUBLIC.
                        </p>

                        <v-text-field
                            ref="paletteLabelInput"
                            v-model="editPaletteLabel"
                            label="Label"
                            :rules="newPaletteLabelRules"
                            validate-on-blur
                            color="green"
                            required
                            type="text"
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditPaletteLabel" :disabled="!isEditPaletteLabelComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editPaletteLabelDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <!-- <v-dialog v-model="editPaletteCommentDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the palette comment</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The palette comment is a place where you can add more information for your own reference about how you use the palette.
                            You can change it at any time. The comment is PRIVATE. It is NOT shown to users.
                        </p>
                        <v-textarea v-model="editablePaletteComment" label="Palette comment"></v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditPaletteComment" :disabled="!isEditPaletteCommentComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editPaletteCommentDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog> -->
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
import PaletteColorList from '@/components/account-dashboard/PaletteColorList.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import BrandBar from '@/components/account-dashboard/BrandBar.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        PaletteColorList,
        AccessDeniedOverlay,
        BrandBar,
    },
    data: () => ({
        // affectedEmailContactList: [],

        error: null,
        forbiddenError: false,
        account: null,
        brand: null,
        palette: null,
        invite: null,
        isDraft: null,

        // edit form title dialog
        editPaletteLabelDialog: false,
        editPaletteCommentDialog: false,
        editablePaletteComment: null,

        // edit palette label in place
        isEditingPaletteLabel: false,
        editPaletteLabel: null,

        // edit palette mode in place
        isEditingPaletteMode: false,
        editPaletteMode: null,
        modeChoices: [
            { text: 'Light Mode', value: 'light' },
            { text: 'Dark Mode', value: 'dark' },
        ],

        // edit palette media type in place
        // isEditingPaletteMediaType: false,
        // editPaletteMediaType: null,
        // mediaTypeChoices: [
        //     { text: 'All', value: 'all' },
        //     { text: 'Print', value: 'print' },
        //     { text: 'Screen', value: 'screen' },
        // ],
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.form !== null;
        },
        isEditPaletteLabelComplete() {
            return typeof this.editPaletteLabel === 'string' && this.editPaletteLabel.trim().length > 0;
        },
        isEditPaletteCommentComplete() {
            return typeof this.editablePaletteComment === 'string' && this.editablePaletteComment.trim().length > 0;
        },
        paletteMode() {
            return this.modeChoices.find((item) => item.value === this.palette.mode)?.text;
        },
        // paletteMediaType() {
        //     return this.mediaTypeChoices.find((item) => item.value === this.palette.media_type)?.text;
        // },
    },
    watch: {
        isEditingPaletteLabel(newValue) {
            if (newValue) {
                this.editPaletteLabel = this.palette.label;
                setTimeout(() => { this.$nextTick(() => this.$refs.paletteLabelInput.focus()); }, 1);
            }
        },
        isEditingPaletteMode(newValue) {
            if (newValue) {
                this.editPaletteMode = this.palette.mode;
            }
        },
        $route(newValue, oldValue) {
            if (newValue.query.brand_id !== oldValue.query.brand_id) {
                this.loadBrand(newValue.query.brand_id);
            }
        },
        // isEditingPaletteMediaType(newValue) {
        //     if (newValue) {
        //         this.editPaletteMediaType = this.palette.media_type;
        //     }
        // },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadPalette() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadPalette: true });
                const response = await this.$client.account(this.$route.params.accountId).palette.get({ id: this.$route.params.paletteId });
                console.log(`loadPalette: response ${JSON.stringify(response)}`);
                if (response) {
                    this.palette = response;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.comment ?? '';
                    // this.palette.comment ??= '';
                    // this.palette.reply_to ??= '';
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load palette');
                }
            } catch (err) {
                console.error('failed to load palette', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadPalette: false });
            }
        },
        async checkIsDraft() {
            try {
                this.$store.commit('loading', { checkIsDraft: true });
                const response = await this.$client.account(this.$route.params.accountId).palette.check({ brand_id: this.$route.query.brand_id, palette_id: this.$route.params.paletteId, item: 'draft' });
                console.log(`checkIsDraft: response ${JSON.stringify(response)}`);
                this.isDraft = response?.isDraft;
            } catch (err) {
                console.error('failed to load palette', err);
                this.isDraft = null;
            } finally {
                this.$store.commit('loading', { checkIsDraft: false });
            }
        },
        async save(paletteAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditPalette: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).palette.edit({ id: this.$route.params.paletteId }, paletteAttr);
                console.log(`saveEditPalette: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.isDraft = true;
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit palette' });
                return false;
            } catch (err) {
                console.error('failed to edit palette', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit palette' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditPalette: false });
            }
        },
        editPaletteLabel() {
            this.editPaletteLabelDialog = true;
            this.editPaletteLabel = this.palette.label;
        },
        editPaletteComment() {
            this.editPaletteCommentDialog = true;
            this.editablePaletteComment = this.palette.comment;
        },
        async saveEditPaletteLabel() {
            const isEdited = await this.save({ label: this.editPaletteLabel });
            // this.editPaletteLabelDialog = false;
            this.isEditingPaletteLabel = false;
            if (isEdited) {
                this.$set(this.palette, 'label', this.editPaletteLabel);
            }
        },
        async saveEditPaletteMode() {
            const isEdited = await this.save({ mode: this.editPaletteMode });
            this.isEditingPaletteMode = false;
            if (isEdited) {
                this.$set(this.palette, 'mode', this.editPaletteMode);
            }
        },
        // async saveEditPaletteMediaType() {
        //     const isEdited = await this.save({ media_type: this.editPaletteMediaType });
        //     this.isEditingPaletteMediaType = false;
        //     if (isEdited) {
        //         this.$set(this.palette, 'media_type', this.editPaletteMediaType);
        //     }
        // },
        async saveEditPaletteComment() {
            const isEdited = await this.save({ comment: this.editablePaletteComment });
            this.editPaletteCommentDialog = false;
            if (isEdited) {
                this.$set(this.palette, 'comment', this.editablePaletteComment);
            }
        },
        async saveEditPaletteColorList({ paletteId, role, list }) {
            console.log(`saveEditPaletteColorList for palette id ${paletteId} role ${role} list ${JSON.stringify(list)}`);
            const content = this.palette.content ?? {};
            content[role] = list;
            const isEdited = await this.save({ content });
            // this.editPaletteCommentDialog = false;
            if (isEdited) {
                this.$set(this.palette, 'content', content);
            }
        },
        async publish() {
            try {
                this.error = false;
                this.$store.commit('loading', { publish: true });
                const response = await this.$client.account(this.$route.params.accountId).palette.publish({ id: this.$route.params.paletteId });
                console.log(`publish: response ${JSON.stringify(response)}`);
                if (response?.isPublished) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.isDraft = false;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish color palette' });
                }
            } catch (err) {
                console.error('failed to publish color palette', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish color palette' });
            } finally {
                this.$store.commit('loading', { publish: false });
            }
        },
        async loadBrand(id) {
            try {
                this.error = false;
                this.$store.commit('loading', { loadBrand: true });
                const response = await this.$client.account(this.$route.params.accountId).brand.get({ id });
                console.log(`loadBrand: response ${JSON.stringify(response)}`);
                if (response) {
                    this.brand = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load brand');
                }
            } catch (err) {
                console.error('failed to load brand', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadBrand: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadPalette();
        this.checkIsDraft();
        if (this.$route.query.brand_id) {
            this.loadBrand(this.$route.query.brand_id);
        }
    },
};
</script>
