<template>
    <v-card flat>
        <v-toolbar flat color="grey lighten-5">
            <v-toolbar-title>
                <font-awesome-icon :icon="['fas', 'store-alt']"></font-awesome-icon>
                <span class="ml-4" v-if="brand"><router-link :to="{ name: 'account-edit-brand', params: { accountId, brandId } }" style="text-decoration: none; cursor: pointer; color: #333333;">{{ brand.name }}</router-link></span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon>
                <font-awesome-icon :icon="['fas', 'globe']"></font-awesome-icon>
            </v-btn> -->
            <!-- <template #extension v-if="accountId">
                <span style="font-size: 0.75em;">
                    <router-link :to="{ name: 'account-view-domain', params: { accountId, domain } }">Overview</router-link>
                    <span class="mx-4"></span>
                    <router-link :to="{ name: 'account-edit-dns', params: { accountId, domain } }">DNS Records</router-link>
                </span>
            </template> -->
            <span style="font-size: 0.75em;">
                <router-link :to="{ name: 'account-edit-brand', params: { accountId, brandId } }">Edit</router-link>
                <!-- <span class="mx-4"></span>
                <router-link :to="{ name: 'account-edit-dns', params: { accountId, domain } }">DNS Records</router-link> -->
            </span>
        </v-toolbar>
    </v-card>
</template>
<script>
export default {
    props: ['accountId', 'brandId', 'brand'],
};
</script>
